/* Defaults */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/sf-ui-display');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@font-face {
  font-family: 'SF UI Display';
  src: url('./assets/fonts/FontsFree-Net-SF-UI-Display-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./assets/fonts/sf-ui-display-bold-58646a511e3d9.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./assets/fonts/sf-ui-display-heavy-586470160b9e5.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./assets/fonts/sf-ui-display-light-58646b33e0551.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./assets/fonts/sf-ui-display-medium-58646be638f96.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./assets/fonts/sf-ui-display-semibold-58646eddcae92.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./assets/fonts/sf-ui-display-thin-58646e9b26e8b.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./assets/fonts/sf-ui-display-ultralight-58646b19bf205.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('./assets/fonts/SFPRODISPLAYBOLD.OTF') format('opentype');
  font-weight: 400;
  font-style: normal;
}

html,
body {
  /* font-family: 'SHMediumDisplay', sans-serif; */
  font-family: 'SF UI Display';
}
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Primary Variables */
:root {
  --primary: 'green';
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: gray;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
/* .css-vnkopk-MuiStepLabel-iconContainer {
  padding: 0 !important;
}
.css-5r88sx-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0 !important;
} */

.MuiInputAdornment-root
  .MuiIconButton-edgeEnd.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent !important;
}
